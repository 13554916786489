<template>
  <Dialog
    v-model:visible="signatureDialog"
    :style="{ width: '600px' }"
    header="Customer Signature"
    class="p-fluid"
    :modal="true"
    :closable="true"
    @hide="closeSignature"
  >
    <div class="row">
      <div class="p-col-12">
         <h6>For Invoice #{{receiptID}}</h6>
        <div class="p-field text-center">
          <canvas id="myCanvas"  ref="myCanvas" width="400" height="300" @mousemove="draw" @mousedown="beginDrawing" @mouseup="stopDrawing" />
        </div>
        <div class="p-field">
          <label for="sigCustomerName">Customer Pickup Name</label>
          <InputText
            id="sigCustomerName"
            placeholder="Customer Name"
            v-model="sigCustomerName"
            :class="{ 'p-invalid': submitted && !sigCustomerName }"
          />
          <small
            class="p-invalid"
            v-if="submitted && !sigCustomerName"
            >Customer Name is required.</small
          >
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeSignature"
      />
      <Button label="Save" @click="saveSignature" icon="pi pi-check" class="p-button-primary" />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Transaction from "../service/Transaction";

@Options({
  props: {
    receiptDetails: Object,
  },
  watch: {
    receiptDetails(obj) {
      this.receiptID = obj.receiptID;
      this.signatureDialog = obj.signatureDialog;
    },
  },
  emits: ["updateSignatureStatus"],
})
export default class SignatureDailog extends Vue {
  private toast;
  private submitted = false;
  private isDrawing = false;
  private sigCustomerName = '';
  private signatureDialog = false;
  private yCordinates = 0;
  private xCordinates = 0;
  private receiptID = '';
  private txnService;

  created() {
    this.txnService = new Transaction();
    this.toast = new Toaster();
  }

  drawLine(x1, y1, x2, y2) {
      const canvas = this.$refs["myCanvas"] as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      if(ctx != null)
      {
        ctx.beginPath();
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.closePath();
      }
    }

    draw(e) {
      if(this.isDrawing) {
        this.drawLine(this.xCordinates, this.yCordinates, e.offsetX, e.offsetY);
        this.xCordinates = e.offsetX;
        this.yCordinates = e.offsetY;
      }
    }

    beginDrawing(e) {
      this.xCordinates = e.offsetX;
      this.yCordinates = e.offsetY;
      this.isDrawing = true;
    }

    stopDrawing(e) {
      if (this.isDrawing) {
        this.drawLine(this.xCordinates, this.yCordinates, e.offsetX, e.offsetY);
        this.xCordinates = 0;
        this.yCordinates = 0;
        this.isDrawing = false;
      }
    }

    saveSignature(e)
    {
      e.preventDefault();
      this.submitted = true;
      if (this.sigCustomerName.trim()) {
        const canvas = this.$refs["myCanvas"] as HTMLCanvasElement;
        const base64 = canvas.toDataURL('image/png');
         this.txnService.saveSignature(
            base64,
            this.sigCustomerName,
            this.receiptID
      )
      .then((res) => {
        this.toast.handleResponse(res);
      });
        this.closeSignature();
      }
    }

    closeSignature()
    {
      this.$emit("updateSignatureStatus", false);
    }
}
</script>
<style scroped>

#myCanvas {
  border: 1px solid grey;
}
</style>
